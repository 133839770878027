import type { BadgeColor } from '#ui/types'
import { InvoiceType } from '~/types/misc'

export const badgeTypes: Record<
  InvoiceType,
  { color: BadgeColor; label: string }
> = {
  [InvoiceType.INVOICE_RECEIPT]: { color: 'green', label: 'Fatura-Recibo' },
  [InvoiceType.INVOICE]: { color: 'amber', label: 'Fatura' },
  [InvoiceType.RECEIPT]: { color: 'fuchsia', label: 'Recibo' },
  [InvoiceType.CREDIT_NOTE]: { color: 'blue', label: 'Nota de Crédito' },
  [InvoiceType.DEBIT_NOTE]: { color: 'red', label: 'Nota de Débito' },
}

export function calculateTotalPrices(
  items: {
    id: number
    name: string
    price: number
    quantity: number
    discount: number
    vat: number
  }[],
) {
  let subtotal = 0
  let vat = 0

  if (items) {
    for (const item of items) {
      if (!item.price || Number.isNaN(item.price)) continue

      const itemSubtotal =
        item.price * item.quantity * (1 - item.discount / 100)
      subtotal += itemSubtotal
      vat += itemSubtotal * (item.vat / 100)
    }
  }

  const formattedSubtotal = formatCurrency(subtotal.toFixed(2))
  const formattedVat = formatCurrency(vat.toFixed(2))
  const total = formatCurrency((subtotal + vat).toFixed(2))

  return {
    subtotal: formattedSubtotal,
    vat: formattedVat,
    total,
  }
}
